//import axios
import axios from "axios";

const Api = axios.create({
  //set default endpoint API
  // baseURL: 'http://api.alazizqolbu.com/api'
  //   baseURL: "http://127.0.0.1:8000/api",
  baseURL: "https://donation.theprime.id/api",
});

export default Api;
